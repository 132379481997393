<template>
  <div>
    <md-scroll-view
          ref="scrollView"
          v-show="!isNull"
          auto-reflow
          :scrolling-x="false"
          class="scroll"
          @end-reached="loadMore">
        <div >
          <div class="activity" v-for="(item,index) in lists" :key="index">
            <div class="item">
              <div class="left">
                <img class="img" :src="item.headimg"/>
              </div>
              <div class="middle">
                <span>客户姓名：{{ item.name }}</span>
                <br/>
                <span>客户电话：{{ item.mobile }}</span>
                <br/>
                <span>报名人数：{{ item.rennum}}</span>
              </div>
              <div class="right">
              </div>
            </div>
            <div class="item">
              <div class="time">
                <span>报名时间：{{ item.btime }}</span>
              </div>
            </div>
          </div>
        </div>
        <md-scroll-view-more
            slot="more"
            :is-finished="loading" />
      </md-scroll-view>
    <div class="nodata" v-show="isNull">
      <img class="kongimg" src="../../../../assets/image/null.png"/>
    </div>
    <div class="btn">
      <div class="applyBtn"
           :style="`backgroundColor:${colorprimary}`"
	   @click="invite">活动详情</div>
    </div>
  </div>
</template>
<script>
import { list } from '@/api/abt/customerOperation/invitationletter'
import { getStorage } from '@/lib/util'
import loadMore from '@/mixins/loadmore'
export default {
  mixins: [ loadMore ],
  data () {
    return {
      ossurl: '',
      height: '',
      empno: '',
      suserid: '',
      user: '',
      pageNo: 1,
      size: 9,
      total: 0,
      totalPage: 0,
      loading: false,
      lists: [],
      isNull: true,
      form: {
        osno: '',
        stitle: '',
        smark: '',
        sdesc: '',
        pichttp: '',
        fbtime: '',
        conthttp: '',
        autor: '',
        remark: '',
        headimg: ''
      },
      sno: '',
      colorprimary: ''
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.user = getStorage('u_s', {})
    this.colorprimary = getStorage('theme', '')
    this.height = document.body.clientHeight
    this.form = this.$route.query.form
    this.sno = this.$route.query.sno
    this.empno = this.$route.query.empno
    this.suserid = this.$route.query.suserid
    this.getData()
  },
  mounted () {
    document.querySelector('.scroll').style.height = `${this.height - 40}px`
  },
  methods: {
    async getData (isInit = true) {
      let { pageNo, size } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let data = {
        page: this.pageNo,
        size,
        sno: this.sno
      }
      let res = await list(data)
      this.lists = isInit ? res.data.data.rows : this.lists.concat(res.data.data.rows)
      if (this.lists.length == 0) {
        this.isNull = true
      } else {
        this.isNull = false
      }
      this.total = res.data.data.total
      this.totalPage = res.data.data.totalpage
      return res
    },
    // getData () {
    //   let data = {
    //     sno: this.sno,
    //     page: 1,
    //     size: 10
    //   }
    //   list(data).then(res => {
    //     this.lists = res.data.data.rows
    //     this.listLength = this.lists.length
    //   })
    // },
    invite () {
      this.$router.push(
        { name: 'invite', query: { sno: this.sno, empno: this.empno, suserid: this.user.userid } }
      )
    }
  }
}
</script>
<style lang="stylus" scoped>
.scroll
  overflow-y auto
.activity
  margin auto 50px
  padding-top 50px
  padding-bottom 30px
  border-bottom 1px solid lightgrey
  .left
    width 250px
    float left
    .img
      width 220px
      height 220px
      border-radius 20px
  .middle
    span
      font-size 40px
      line-height 70px
  .right
    padding-left 500px
    .btn
      display: inline-block;
      border-radius: 0.3rem;
      font-size: 0.35rem;
      height: 0.8rem;
      line-height: 0.8rem;
      width: 2rem;
      text-align: center;
    .btn
      color: #f3a512;
      background: #f7f1e6;
      border: 1px solid #f3e3c3;
      margin-right: .2rem;
    .btn1
      color: #4194e2;
      background: #ecf3fb;
      border: 1px solid #bcdaf7;
  .time
    padding-top 40px
    span
      font-size 40px
      line-height 70px
.btn
  position fixed
  bottom 0
  width 100%
  .applyBtn
    text-align center
    padding 20px 30px
    width: 100%;
    background-color color-primary
    color #fff
    border-radius 10px
    font-size 40px
.nodata
  padding-top 50%
  text-align center
  .kongimg
    width: 300px;
    height auto
</style>
